import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@gasbuddy/react-components';
import MagicLinkSent from '../MagicLinkSent';
import LoginCodeEntry from '../LoginCodeEntry';
import { MagicLinkStatus } from '../../reducers/magiclinks';

export default function MagicLinkButton({ deviceLabel, useCode, variant, ...rest }) {
  return (
    <React.Fragment>
      <Modal
        content={() => (
          useCode ? (
            <LoginCodeEntry
              variant={variant}
              deviceLabel={deviceLabel}
            />
          ) : (
            <MagicLinkSent
              variant={variant}
              deviceLabel={deviceLabel}
            />
          )
        )}
        forceIsShowing={!!variant}
        size="sm"
      />
      <Button
        fluid
        secondary
        type="submit"
        {...rest}
      >
        {useCode ? 'Email me a login code' : 'Email me a Login link'}
      </Button>
    </React.Fragment>
  );
}

MagicLinkButton.propTypes = {
  deviceLabel: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(MagicLinkStatus)),
  useCode: PropTypes.bool,
};

MagicLinkButton.defaultProps = {
  deviceLabel: undefined,
  variant: undefined,
  useCode: false,
};
