import { generateRequest } from '.';

export const Action = {
  Auth: {
    LoginMagicLinkPending: 'LoginMagicLink_PENDING',
    LoginMagicLinkCompleted: 'LoginMagicLink_FULFILLED',
    LoginMagicLinkFailed: 'LoginMagicLink_FAILED',
    SocialConnectMagicLinkPending: 'SocialConnectMagicLink_PENDING',
    SocialConnectMagicLinkCompleted: 'SocialConnectMagicLink_FULFILLED',
    SocialConnectMagicLinkFailed: 'SocialConnectMagicLink_FAILED',
  },
};

export const requestMagicLink = body => generateRequest({
  method: 'POST',
  endpoint: '/login-link',
  body,
  types: [
    Action.Auth.LoginMagicLinkPending,
    Action.Auth.LoginMagicLinkCompleted,
    Action.Auth.LoginMagicLinkFailed,
  ],
});

export const requestSocialConnectMagicLink = body => generateRequest({
  method: 'POST',
  endpoint: '/social/connect',
  credentials: 'include',
  body,
  types: [
    Action.Auth.SocialConnectMagicLinkPending,
    Action.Auth.SocialConnectMagicLinkCompleted,
    Action.Auth.SocialConnectMagicLinkFailed,
  ],
});
