import { Action } from '../actions/signup';
import { LoginStatus } from './login';

export const defaultState = {
  accountId: undefined,
  destination: undefined,
  error: undefined,
  memberName: undefined,
};

export default function signupReducer(state = defaultState, action) {
  const body = action.payload || {};
  const { response } = body;

  switch (action.type) {
    case Action.Auth.SignUpPending:
      return {
        ...state,
        error: undefined,
        status: LoginStatus.InProgress,
      };

    case Action.Auth.SignUpCompleted:
      return {
        ...state,
        status: LoginStatus.LoggedIn,
        // if social signup was attempted for an already connected account, destination would be set differently
        destination: response?.destination || body.destination,
        // if social signup was attempted for an already connected account then memberName and accountId won't be present
        memberName: response?.memberName,
        accountId: response?.accountId || body.accountId,
        network: response?.socialNetwork,
      };

    case Action.Auth.SignUpFailed:
      return {
        ...state,
        error: response?.error || body.message,
        status: LoginStatus.Failed,
      };
    default: {
      return state;
    }
  }
}
