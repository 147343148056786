import React from 'react';
import classnames from 'classnames/bind';
import {
  Text,
} from '@gasbuddy/react-components';

import styles from './FormSubHeader.module.css';

const cx = classnames.bind(styles);

export default function FormSubHeader(props) {
  return (
    <Text as="p" className={cx('subheader', 'message')} {...props} />
  );
}
