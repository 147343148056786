export default class BusinessError extends Error {
  constructor(code, status = 400, message = '') {
    super(message);
    this.code = code;
    this.domain = 'identity-web';
    this.status = status;
    Error.captureStackTrace(this, BusinessError);
  }

  withMessage(message) {
    this.message = message;
    return this;
  }
}
