import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { goTo } from '../../../lib/goTo';
import ReactGTM from '../../../lib/gtm';
import useAnalyticsContext from '../../../lib/hooks/useAnalyticsContext';
import ANALYTICS_EVENTS from '../../constants/analyticsEvents';
import { LoginStatus } from '../../reducers';
import ChangePassword from '../ChangePassword';
import Consent from '../Consent';
import ConstrainedRoute from '../ConstrainedRoute';
import DefaultHelmet from '../DefaultHelmet';
import Footer from '../Footer';
import Login from '../Login';
import Signup from '../Signup';
import UnknownPage from '../UnknownPage';
import styles from './Application.module.css';

const cx = classnames.bind(styles);

export default function Application({ accountId, memberName, destination, loginStatus, location }) {
  const analytics = useAnalyticsContext();
  if (destination && loginStatus === LoginStatus.LoggedIn && typeof window !== 'undefined') {
    analytics.identifyUser({
      id: memberName,
    });
    analytics.tagEvent({ name: ANALYTICS_EVENTS.IAM_User_Successfully_Logged_In });

    ReactGTM.event('Login', {
      userID: accountId,
    });

    goTo(destination);
  }

  const search = location?.search || '';
  const pathname = location?.pathname || '/';

  return (
    <>
      <DefaultHelmet />
      <div className={cx('gradient')} />
      <Switch>
        <ConstrainedRoute exact path="/login/:partner?" component={Login} noindex={false}>
          <Helmet>
            <title>GasBuddy - Login to Continue Saving</title>
            <meta property="og:title" content="GasBuddy - Login to Continue Saving" />
            <meta name="twitter:title" content="GasBuddy - Login to Continue Saving" />
            <meta name="description" content="Login to save the most with GasBuddy." />
            <meta property="og:description" content="Login to save the most with GasBuddy." />
            <meta name="twitter:description" content="Login to save the most with GasBuddy." />
            <link rel="canonical" href={`https://iam.gasbuddy.com${pathname}`} />
          </Helmet>
          <Footer>
            New to GasBuddy?&ensp;<Link to={{ pathname: pathname.replace(/^\/login/, '/signup'), search }}>Sign Up</Link>
          </Footer>
        </ConstrainedRoute>

        <ConstrainedRoute exact path="/social/login/:partner" component={Login} />

        <ConstrainedRoute exact path="/consent/:partner" component={Consent} />

        <ConstrainedRoute exact path="/signup/:partner?" component={Signup} noindex={false}>
          <Helmet>
            <title>GasBuddy - Fuel Better and Register Today</title>
            <meta property="og:title" content="GasBuddy - Fuel Better and Register Today" />
            <meta name="twitter:title" content="GasBuddy - Fuel Better and Register Today" />
            <meta name="description" content="GasBuddy has saved users over $3.1 billion. Join the millions of drivers who have helped each other save on gas." />
            <meta property="og:description" content="GasBuddy has saved users over $3.1 billion. Join the millions of drivers who have helped each other save on gas." />
            <meta name="twitter:description" content="GasBuddy has saved users over $3.1 billion. Join the millions of drivers who have helped each other save on gas." />
            <link rel="canonical" href={`https://iam.gasbuddy.com${pathname}`} />
          </Helmet>
          <Footer>
            Already a GasBuddy member?&ensp;<Link to={{ pathname: pathname.replace(/^\/signup/, '/login'), search }}>Log In</Link>
          </Footer>
        </ConstrainedRoute>

        <ConstrainedRoute exact path="/password" component={ChangePassword} size="sm" />

        <ConstrainedRoute exact path="/go/:code" component={Login} />

        <ConstrainedRoute exact path="/go" component={Login} />

        <ConstrainedRoute exact path="/social/connect" component={Login} />

        <ConstrainedRoute exact path="/social/connect/:code" component={Login} />

        <Route
          exact
          path="/reset"
          render={routerProps => (
            <Redirect
              to={{
                pathname: '/password',
                search: routerProps.location.search,
              }}
            />
          )}
        />

        <Route
          exact
          path="/change"
          render={routerProps => (
            <Redirect
              to={{
                pathname: '/password',
                search: routerProps.location.search,
              }}
            />
          )}
        />

        <ConstrainedRoute component={UnknownPage} />

      </Switch>
    </>
  );
}

Application.propTypes = {
  accountId: PropTypes.number,
  memberName: PropTypes.string,
  destination: PropTypes.string,
  loginStatus: PropTypes.oneOf(Object.values(LoginStatus)),
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }),
};

Application.defaultProps = {
  accountId: undefined,
  memberName: undefined,
  destination: undefined,
  loginStatus: undefined,
  location: {},
};
