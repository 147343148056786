import { Actions, Checkbox, Form, Header, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { goTo } from '../../../lib/goTo';
import useAnalyticsContext from '../../../lib/hooks/useAnalyticsContext';
import ANALYTICS_EVENTS from '../../constants/analyticsEvents';

function modifyArray(arr, value, isIncluded) {
  if (isIncluded && !arr.includes(value)) {
    return [...arr, value];
  }
  if (!isIncluded && arr.includes(value)) {
    const index = arr.indexOf(value);
    return arr.splice(index, 1);
  }
  return arr;
}

export default function Consent({
  scopeRequests,
  membername,
  name,
  destination,
  denyConsent,
  grantConsent,
}) {
  useEffect(() => {
    if (destination) {
      goTo(destination);
    }
  }, [destination]);

  const [checkState, setCheckState] = useState(scopeRequests.map(s => s.id));
  const analytics = useAnalyticsContext();

  const onConsentClick = useCallback((e) => {
    e.preventDefault();
    analytics.tagEvent({ name: ANALYTICS_EVENTS.IAM_Consent_Approved });
    grantConsent(checkState);
  }, [analytics, checkState, grantConsent]);

  const onCancelClick = useCallback((e) => {
    e.preventDefault();
    analytics.tagEvent({ name: ANALYTICS_EVENTS.IAM_Consent_Denied });
    denyConsent();
  }, [analytics, denyConsent]);

  useEffect(() => {
    analytics.tagPageView({ name: ANALYTICS_EVENTS.IAM_Consent_Displayed });
  }, [analytics]);

  return (
    <React.Fragment>
      <Header as="h3">Link Your GasBuddy Account</Header>
      <Text as="p">
        {membername}, <Text bold>{name}</Text> will receive your email address and GasBuddy username.
      </Text>
      <br />
      <br />
      <Form action="/oauth/consent" method="POST">
        {!!scopeRequests?.length && (
          <React.Fragment>
            {scopeRequests.map((request, ix) => (
              <Checkbox
                key={request.id}
                id={request.id}
                label={request.desc}
                onChange={e => setCheckState(modifyArray(checkState, ix, e.target.checked))}
                checked={checkState[ix]}
                disabled={!request.optional}
              />
            ))}
          </React.Fragment>
        )}
        <br />
        <Actions>
          <Actions.Button primary cta onClick={onConsentClick}>
            Continue
          </Actions.Button>
          <Actions.Button link onClick={onCancelClick}>
            Cancel
          </Actions.Button>
        </Actions>
      </Form>
      <br />
      <br />
    </React.Fragment>
  );
}

Consent.propTypes = {
  scopeRequests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.string,
      optional: PropTypes.boolean,
    }),
  ),
  membername: PropTypes.string.isRequired,
  denyConsent: PropTypes.func.isRequired,
  grantConsent: PropTypes.func.isRequired,
  destination: PropTypes.string,
  name: PropTypes.string,
};

Consent.defaultProps = {
  scopeRequests: [],
  destination: null,
  name: '',
};
