import { Button, Checkbox, Form, Input, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import checkEmailAddress from '../../../lib/checkEmailAddress';
import useAnalyticsContext from '../../../lib/hooks/useAnalyticsContext';
import useReturnUrl from '../../../lib/hooks/useReturnUrl';
import { detectCountryCode, testEmail } from '../../../lib/utils';
import { VALIDATION_TIMEOUT } from '../../constants';
import ANALYTICS_EVENTS from '../../constants/analyticsEvents';
import { LoginStatus } from '../../reducers/login';
import FormSubHeader from '../FormSubHeader';
import PostalCodeInput from '../PostalCodeInput';
import styles from './SignupForm.module.css';

const cx = classnames.bind(styles);

export default function SignupForm({
  email: defaultEmail,
  errorMessage,
  loginStatus,
  signup,
  partner,
  signedRequest,
  socialNetwork,
}) {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(undefined);
  const [postal, setPostal] = useState('');
  const [postalError, setPostalError] = useState(undefined);
  const [countryCode, setCountryCode] = useState(undefined);
  const [emailPrefsSelected, setEmailPrefsSelected] = useState(false);
  const analytics = useAnalyticsContext();
  const returnUrl = useReturnUrl();

  useEffect(() => {
    analytics.tagPageView({ name: ANALYTICS_EVENTS.IAM_Account_Signup });
  }, [analytics]);

  useEffect(() => {
    if (defaultEmail) {
      setEmail(defaultEmail);
    }
  }, [defaultEmail]);

  // This should only be called when the country code is about to update, as we are setting a default value for
  // the email preferences checkbox when we do so: USA = true, rest = false
  const handleCountryCodeUpdate = (postalCode) => {
    const newCountryCode = detectCountryCode(postalCode);
    setEmailPrefsSelected(newCountryCode === 'USA');
    setCountryCode(newCountryCode);
  };

  const onEmailBlur = useCallback(async () => {
    const emailSuggestion = await checkEmailAddress(email);

    if (emailSuggestion) {
      setEmailError(`Did you mean ${emailSuggestion}?`);
    }
  }, [email]);

  const onEmailChange = useCallback(({ target }) => {
    setEmail(target.value);
    setEmailError(undefined);
  }, []);

  const onPostalChange = useCallback(({ target }) => {
    setPostal(target.value);
    setPostalError(undefined);
  }, []);

  const onEmailPrefsChange = useCallback(() => {
    setEmailPrefsSelected(!emailPrefsSelected);
  }, [emailPrefsSelected, setEmailPrefsSelected]);

  useEffect(() => {
    const timerId = setTimeout(() => { handleCountryCodeUpdate(postal); }, VALIDATION_TIMEOUT);
    return () => clearTimeout(timerId);
  }, [postal]);

  useEffect(() => {
    // NOTE: if we had an error on this screen, then jumped to /login, then came back, our status would still be failed
    // but we'd have no content in the fields. Therefore, make sure we don't show an error message on an empty field.
    if (loginStatus === LoginStatus.Failed && !!email) {
      if (errorMessage) {
        // if we have an error message from the reducer, it'll only be because of an email address issue
        // the postal code validation issues are handled by the PostalCodeInput validation onSignUpClick
        setEmailError(errorMessage);
      }
      return;
    }

    if (loginStatus === LoginStatus.InProgress) {
      setEmailError(errorMessage);
      setPostalError(errorMessage);
    }
  }, [loginStatus, email, errorMessage, setEmailError, setPostalError]);

  const onSignUpClick = (e) => {
    e.preventDefault();
    let validEmail = testEmail(email);
    const validPostalCode = !!countryCode;

    if (!validEmail) {
      setEmailError('Email address is not valid.');
    }

    const [prefix, fullDomain] = email.split('@');
    if (prefix.length > 30 || prefix.length < 1) {
      setEmailError('Your email prefix must be between 1 and 30 characters long.');
      validEmail = false;
    }

    if (fullDomain) {
      const [domain, extension] = fullDomain.split('.');
      if (fullDomain.length > 50 || domain.length < 1) {
        setEmailError('Your email domain must be between 1 and 50 characters long.');
        validEmail = false;
      }

      if (!extension || extension?.length < 1) {
        setEmailError('Your email domain must have an extension.');
        validEmail = false;
      }
    }

    if (!validPostalCode) {
      setPostalError('This is not a valid postal code.');
    }

    if (!validEmail || !validPostalCode) {
      return;
    }

    analytics.tagEvent({ name: ANALYTICS_EVENTS.IAM_Signup_Clicked });

    signup(partner, {
      email,
      postalCode: postal,
      subscribeChecked: emailPrefsSelected,
      country: countryCode,
      returnUrl,
      signedRequest,
      socialNetwork,
    });
  };

  return (
    <React.Fragment>
      <FormSubHeader>
        We&apos;ve saved users over $2.3 billion. Join the millions of drivers who have helped each other save on gas.
      </FormSubHeader>
      <Form onSubmit={onSignUpClick} method="POST" className={cx('form')}>
        <div className={cx('formGroup')}>
          <Input
            id="email"
            name="email"
            data-testid="emailInput"
            label="Email Address"
            isValid={!emailError}
            error={emailError}
            value={email}
            onBlur={onEmailBlur}
            onChange={onEmailChange}
            autoComplete="username"
          />
        </div>
        {!!emailError && <br />}
        <div className={cx('formGroup')}>
          <PostalCodeInput
            id="postalcode"
            data-testid="postalCodeInput"
            name="postalCode"
            label="Zip or Postal Code"
            error={postalError}
            value={postal}
            onChange={onPostalChange}
          />
        </div>
        {!!postalError && <br />}
        {(countryCode === 'CAN' || countryCode === 'AUS') && (
          <div className={cx('emailPreferences')}>
            <Checkbox
              id="email_prefs_checkbox"
              name="subscribeChecked"
              label="Please send me Price Hike Alerts, promotional offers, and other information from GasBuddy"
              checked={emailPrefsSelected}
              onChange={onEmailPrefsChange}
            />
          </div>
        )}
        <br />
        <Text className={cx('disclaimer')}>
          By signing up, I agree to GasBuddy’s&ensp;
          <a href="https://www.gasbuddy.com/Disclaimer" rel="noopener noreferrer" target="_blank" className={cx('textLink')}>
            Terms&nbsp;and&nbsp;Conditions
          </a>
        </Text>
        <br />
        <Button className={cx('submit')} primary fluid type="submit">
          Sign up
        </Button>
      </Form>
    </React.Fragment>
  );
}

SignupForm.propTypes = {
  email: PropTypes.string,
  errorMessage: PropTypes.string,
  loginStatus: PropTypes.number,
  signup: PropTypes.func.isRequired,
  signedRequest: PropTypes.string,
  socialNetwork: PropTypes.number,
  partner: PropTypes.string,
};

SignupForm.defaultProps = {
  email: undefined,
  errorMessage: undefined,
  loginStatus: undefined,
  signedRequest: undefined,
  socialNetwork: undefined,
  partner: undefined,
};
