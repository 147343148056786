import { connect } from 'react-redux';
import Login from './Login';

function mapStateToProps({ login }) {
  const {
    status: loginStatus,
    accountId,
    memberName,
    destination,
  } = login;

  return {
    loginStatus,
    accountId,
    memberName,
    destination,
  };
}

export default connect(mapStateToProps)(Login);
