import { Button, Form, Header, Input, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { goTo } from '../../../lib/goTo';
import useAnalyticsContext from '../../../lib/hooks/useAnalyticsContext';
import ANALYTICS_EVENTS from '../../constants/analyticsEvents';
import { LoginStatus } from '../../reducers/login';
import { MagicLinkStatus } from '../../reducers/magiclinks';
import SupportLink from '../SupportLink';
import styles from './LoginCodeEntry.module.css';

const cx = classnames.bind(styles);

export default function LoginCodeEntry({
  history,
  destination,
  loginStatus,
  errorMessage,
  variant,
  identifierIsUsername,
  deviceLabel,
  redeemLoginCode,
}) {
  const [code, setCode] = useState();
  const [codeError, setCodeError] = useState();
  const analytics = useAnalyticsContext();

  const onCodeEntered = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!code || code.trim().length === 0) {
      setCodeError('Please enter a code.');
      return;
    }
    if (code.trim().length !== 6) {
      setCodeError('That code is not valid.');
      return;
    }
    analytics.tagEvent({ name: ANALYTICS_EVENTS.IAM_Login_CodeEntered });
    await redeemLoginCode(code, history.location.search);
  }, [analytics, code, history.location.search, redeemLoginCode]);

  useEffect(() => {
    if (loginStatus === LoginStatus.LoggedIn && typeof window !== 'undefined') {
      analytics.tagEvent({ name: ANALYTICS_EVENTS.IAM_User_Successfully_Logged_In });
      goTo(destination);
    }
  }, [analytics, destination, loginStatus]);

  const onIdentifierChange = useCallback(({ target }) => {
    setCode(target.value);
    setCodeError(undefined);
  }, []);

  const emailSentString = identifierIsUsername
    ? 'the email address registered to your username'
    : 'that email address';

  switch (variant) {
    case MagicLinkStatus.Sent: {
      return (
        <React.Fragment>
          <Header as="h3">Code Sent!</Header>
          <Form action={history.location.pathname} method="POST" onSubmit={onCodeEntered}>
            <Text as="p">
              We&apos;ve sent a code to {emailSentString}. <br />
              The code will expire within the next <strong>30&nbsp;minutes</strong>.
            </Text>
            <br />
            <Input
              id="code"
              name="code"
              data-test="codeInput"
              label="Login Code"
              isValid={code && code.length === 6}
              error={codeError || errorMessage}
              value={code}
              onChange={onIdentifierChange}
              autoComplete="off"
              className={cx('codeInput', { filled: !!code?.length })}
            />
            <Text as="p" bold>
              <Text>If you don&apos;t receive your login code, </Text>
              <SupportLink>contact support</SupportLink>.
            </Text>
            <br />
            <Button
              primary
              cta
              type="submit"
              loading={loginStatus === LoginStatus.InProgress}
              wide
            >
              Log In
            </Button>
          </Form>
        </React.Fragment>
      );
    }

    // TODO: There is nothing that causes this status to be set
    case MagicLinkStatus.Throttled: {
      return (
        <React.Fragment>
          <Header as="h3">Email already sent.</Header>
          <Text as="p">
            We&apos;ve recently sent a code to {emailSentString}.
            You can try again in <strong>5 minutes.</strong>
          </Text>
          <Text as="p" bold>
            <Text>If you don&apos;t receive your login code, </Text>
            <SupportLink>contact support</SupportLink>.
          </Text>
        </React.Fragment>
      );
    }

    case MagicLinkStatus.InvalidDevice: {
      return (
        <React.Fragment>
          <Header as="h3">We couldn&apos;t log you in.</Header>
          <Text as="p">
            Please use your login code on the device on which you requested it: <br />
          </Text>
          <Text as="p" bold>{deviceLabel}</Text>
          <Text as="p">
            Or you may request another login code on this device.
          </Text>
        </React.Fragment>
      );
    }

    case MagicLinkStatus.InvalidCode: {
      return (
        <React.Fragment>
          <Header as="h3">We couldn&apos;t log you in.</Header>
          <Text as="p">
            The code you entered is either invalid, expired, or previously used.
          </Text>
          <Text as="p">
            To log in, request another code and be sure to use it within <Text bold>30 minutes</Text> of receiving the email.
          </Text>
        </React.Fragment>
      );
    }

    default: {
      return false;
    }
  }
}

LoginCodeEntry.propTypes = {
  variant: PropTypes.number,
  loginStatus: PropTypes.number,
  identifierIsUsername: PropTypes.bool,
  deviceLabel: PropTypes.string,
  destination: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  redeemLoginCode: PropTypes.func,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  }),
};

LoginCodeEntry.defaultProps = {
  variant: undefined,
  loginStatus: undefined,
  identifierIsUsername: false,
  deviceLabel: '',
  errorMessage: undefined,
  redeemLoginCode: () => {},
  history: {
    location: {
      pathname: '/',
      search: '',
    },
  },
};
