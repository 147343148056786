import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { denyConsent, grantConsent } from '../../actions/login';

import ConsentComponent from './Consent';

function mapStateToProps({ auth = {}, login = {} }) {
  return {
    scopeRequests: auth.oauth?.scopeRequests,
    name: auth.oauth?.name,
    membername: auth.membername,
    destination: login.destination,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    denyConsent() {
      dispatch(denyConsent({ query: window.location.search }));
    },
    grantConsent(responses) {
      dispatch(grantConsent({ query: window.location.search, responses }));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConsentComponent));
