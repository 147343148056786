import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  loginWithFacebook,
  loginWithGoogle,
  checkFacebookAccount,
  checkGoogleAccount,
  resetSocialStatus,
  linkSocialAccount,
} from '../../actions/social';
import { requestSocialConnectMagicLink } from '../../actions/magiclinks';
import SocialAuthButtons from './SocialAuthButtons';

const mapStateToProps = ({ auth, social, magiclinks }) => ({
  destination: auth.destination,
  facebookAppId: auth.fbAppId,
  googleAppId: auth.gAppId,
  signedRequest: social.signedRequest,
  errorMessage: social.error,
  token: social.token,
  socialEmail: social.email,
  socialIsLinked: social.isLinked,
  socialNetwork: social.socialNetwork,
  socialStatus: social.status,
  isSendingMagicLink: magiclinks.isLoading,
  magicLinkStatus: magiclinks.modalVariant,
  magicLinkError: magiclinks.magicLinkError,
});

function mapDispatchToProps(dispatch, ownProps) {
  const { type } = ownProps;
  const facebookAction = type === 'signup' ? checkFacebookAccount : loginWithFacebook;
  const googleAction = type === 'signup' ? checkGoogleAccount : loginWithGoogle;

  return {
    onFacebookResponse: ({ signedRequest }, email, returnUrl) => (
      dispatch(facebookAction({ signedRequest, email, returnUrl }))
    ),
    onGoogleResponse: (signedRequest, returnUrl) => (
      dispatch(googleAction({ signedRequest, returnUrl }))
    ),
    onCancel: () => {
      dispatch(resetSocialStatus());
    },
    requestSocialConnectMagicLink: (body) => {
      dispatch(requestSocialConnectMagicLink(body));
    },
    linkAccounts: body => dispatch(linkSocialAccount(body)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SocialAuthButtons));
