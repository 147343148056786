import { generateRequest } from '.';

export const Action = {
  Auth: {
    SignUpFailed: 'Signup_FAILED',
    SignUpCompleted: 'Signup_FULFILLED',
    SignUpPending: 'Signup_PENDING',
  },
};

export const signup = (partner, body) => generateRequest({
  method: 'POST',
  endpoint: partner ? `/signup/${partner}` : '/signup',
  body,
  types: [
    Action.Auth.SignUpPending,
    Action.Auth.SignUpCompleted,
    Action.Auth.SignUpFailed,
  ],
});
