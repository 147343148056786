/* global window */
import { Action as AuthAction } from '../actions';
import { Action as SocialAction } from '../actions/social';
import { LoginStatus } from './login';
import { SocialAccountStatus } from './social';

export const IdentifierStatus = {
  InProgress: 1,
  Valid: 2,
  Unknown: 3,
};

export const defaultState = {
  status: LoginStatus.LoggedOut,
  rememberedGuid: undefined,
  key: undefined,
  socialStatus: undefined,
  account_id: undefined,
  destination: undefined,
  error: undefined,
  identStatus: undefined,
  identityFieldChecked: undefined,
  fbIdentifier: undefined,
  fbid: undefined,
  fbAccessToken: undefined,
  emailSent: undefined,
  errorStatus: undefined,
  failure: undefined,
  pending: undefined,
  invalidOldPassword: undefined,
  passwordChanged: undefined,
  validAccount: undefined,
  membername: undefined,
  messageText: undefined,
  validToken: undefined,
  completed: undefined,
};

export default function (authState = defaultState, action) {
  const { status } = authState;
  const { error, body } = action.payload || {};

  switch (action.type) {
    case AuthAction.Auth.AuthReset: {
      return {
        status: LoginStatus.Reset,
      };
    }

    case SocialAction.Auth.SocialAuthPending:
      return {
        ...authState,
        socialStatus: SocialAccountStatus.InProgress,
      };

    case SocialAction.Auth.SocialAuthDone:
      if (!error && status !== LoginStatus.LoggedIn) {
        // this doesn't necessarily mean a success.
        return {
          ...authState,
          status: action.payload.status >= 400 ? LoginStatus.Failed : LoginStatus.LoggedIn,
          destination: body?.destination,
          socialStatus: body?.socialStatus,
          message: body?.error,
          fbIdentifier: body?.fbIdentifier,
          fbid: body?.fbid,
          fbAccessToken: body?.fbAccessToken,
          // TODO Don't have two different formats for errors!!
          error: body && (body.error || body.message) ? (body.error || body.message) : undefined,
        };
      } if (error) {
        if (status !== LoginStatus.Failed) {
          return {
            ...authState,
            status: LoginStatus.Failed,
          };
        }
      }
      break;

    case AuthAction.Auth.LoggedOut:
      if (authState.status !== LoginStatus.LoggedOut) {
        if (window) {
          window.location = '/';
        }
        // Otherwise we're server side, what to do?
        return {
          ...authState,
          status: LoginStatus.LoggedOut,
        };
      }
      break;

    default:
      break;
  }
  return authState;
}
