import { Image, Panel, Constraint } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route } from 'react-router-dom';
import Footer from '../Footer';
import styles from './ConstrainedRoute.module.css';

const cx = classnames.bind(styles);

const ViewportSize = {
  sm: {
    mobile: 10,
    mobileOffset: 1,
    tablet: 8,
    tabletOffset: 2,
    desktop: 4,
    desktopOffset: 4,
  },
  lg: {
    mobile: 10,
    mobileOffset: 1,
    tablet: 10,
    tabletOffset: 1,
    desktop: 8,
    desktopOffset: 2,
  },
};

export default function ConstrainedRoute({
  component: Component,
  children,
  noindex,
  size,
  ...rest
}) {
  return (
    <Route
      render={routerProps => (
        <React.Fragment>
          {noindex && (
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
          )}
          <Constraint {...ViewportSize[size]}>
            <div className={cx('logo')}>
              <a href="https://www.gasbuddy.com" title="GasBuddy Homepage">
                <Image className={cx('logoImage')} alt="GasBuddy Logo" src="https://static.gasbuddy.com/web/gas-buddy-logo-midnight-txt.svg" />
              </a>
            </div>
            <Panel className={cx('panel')} color="white" rounded={false}>
              <Component {...routerProps} />
            </Panel>
            {(!!children && children) || <Footer />}
          </Constraint>
        </React.Fragment>
      )}
      {...rest}
    />
  );
}

ConstrainedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  children: PropTypes.node,
  noindex: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
};

ConstrainedRoute.defaultProps = {
  children: undefined,
  noindex: true,
  size: 'lg',
};
