import React from 'react';
import PropTypes from 'prop-types';

export default function SupportLink({ children, className, ...rest }) {
  return (
    <a
      href="https://help.gasbuddy.com/hc/en-us/requests/new"
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      {...rest}
    >
      {children}
    </a>
  );
}

SupportLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

SupportLink.defaultProps = {
  children: undefined,
  className: undefined,
};
