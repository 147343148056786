import React from 'react';
import PropTypes from 'prop-types';
import { Loader, Header, Text } from '@gasbuddy/react-components';
import DeferredRedirect from '../DeferredRedirect';

export default function PasswordUpdateRedirect({ destination, autoRedirect }) {
  let deferredRedirect;
  if (autoRedirect) {
    deferredRedirect = <DeferredRedirect to={destination} />;
  }

  return (
    <React.Fragment>
      <Header as="h3">Logging in.</Header>
      <Loader speed="slow" inline />
      <br />
      <br />
      <Text as="p">We&apos;re now logging you into GasBuddy.</Text>
      {deferredRedirect}
    </React.Fragment>
  );
}

PasswordUpdateRedirect.propTypes = {
  destination: PropTypes.string.isRequired,
  autoRedirect: PropTypes.bool,
};

PasswordUpdateRedirect.defaultProps = {
  autoRedirect: false,
};
