import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { signup } from '../../actions/signup';
import SignupForm from './SignupForm';

const mapStateToProps = ({ social, auth, signup: signupReducer }) => {
  const { error, status } = signupReducer;
  const { email, signedRequest, network: socialNetwork } = social;

  return ({
    email,
    errorMessage: error,
    loginStatus: status,
    signedRequest,
    socialNetwork,
    partner: auth?.partner,
  });
};

const mapDispatchToProps = dispatch => ({
  signup: (partner, body) => {
    dispatch(signup(partner, body));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupForm));
