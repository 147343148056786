import classnames from 'classnames/bind';
import React from 'react';
import PropTypes from 'prop-types';
import { FlexGrid, Text } from '@gasbuddy/react-components';
import { version } from '../../../../package.json';
import styles from './Footer.module.css';

const cx = classnames.bind(styles);

export default function Footer({ children }) {
  return (
    <FlexGrid className={cx('footer')} container>
      <FlexGrid.Column className={cx('centered')} mobile={12} tablet={7}>
        {children}
      </FlexGrid.Column>
      <FlexGrid.Column className={cx('textRightTablet')} mobile={12} tablet={5}>
        <Text size="sm">v {version} &copy; {new Date().getFullYear()} GasBuddy LLC</Text>
      </FlexGrid.Column>
    </FlexGrid>
  );
}

Footer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]),
};

Footer.defaultProps = {
  children: undefined,
};
