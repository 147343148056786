import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { redeemLoginCode } from '../../actions/login';

import LoginCodeEntryComponent from './LoginCodeEntry';

function mapStateToProps({ magiclinks, login }) {
  return {
    errorMessage: login.error,
    loginStatus: login.status,
    magicLinkErrorMessage: magiclinks.magicLinkError,
    variant: magiclinks.modalVariant,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    redeemLoginCode(code, query) {
      dispatch(redeemLoginCode({ code, query }));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginCodeEntryComponent));
