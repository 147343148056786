import { useAnalytics } from '@gasbuddy/react-hooks';
import { ThemeProvider } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { LOCALYTICS_CUSTOM_DIMENSIONS } from '../../constants';
import AnalyticsContext from '../../context/analytics';
import ReactGTM from '../../../lib/gtm';
import Application from '../Application';

export default function Router({ accountId, analyticsConfig, memberName }) {
  const analytics = useAnalytics(analyticsConfig);

  if (analytics) {
    if (accountId) {
      analytics.setCustomDimension(LOCALYTICS_CUSTOM_DIMENSIONS.USER_ID, accountId);
    }

    if (memberName) {
      // TODO: check if other identifyUser uses are needed if we have this one in place
      analytics.identifyUser({
        id: memberName,
      });
      analytics.setCustomDimension(LOCALYTICS_CUSTOM_DIMENSIONS.USER_NAME, memberName);
    }
  }

  useEffect(() => {
    if (analyticsConfig?.gtm) {
      ReactGTM.initialize(analyticsConfig.gtm);
    }
  }, [analyticsConfig]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      <ThemeProvider>
        <Switch>
          <Route path="/" component={Application} />
        </Switch>
      </ThemeProvider>
    </AnalyticsContext.Provider>
  );
}

Router.propTypes = {
  accountId: PropTypes.number,
  analyticsConfig: PropTypes.shape({
    localytics: PropTypes.shape({
      domain: PropTypes.string,
      key: PropTypes.string,
    }),
    gtm: PropTypes.shape({}),
  }),
  memberName: PropTypes.string,
};

Router.defaultProps = {
  accountId: undefined,
  analyticsConfig: undefined,
  memberName: undefined,
};
