import { Action as AuthAction } from '../actions';
import { Action as SocialAuthAction } from '../actions/social';

export const LoginStatus = {
  Reset: -1,
  LoggedOut: 0,
  InProgress: 1,
  Failed: 2,
  LoggedIn: 3,
};

export const defaultState = {
  accountId: undefined,
  destination: undefined,
  error: undefined,
  memberName: undefined,
};

export default function loginReducer(state = defaultState, action) {
  const body = action.payload || {};
  const { response } = body;

  switch (action.type) {
    case AuthAction.Auth.ConsentCompleted:
      return {
        ...state,
        destination: response?.destination || body.destination,
      };

    case AuthAction.Auth.LoginPending:
    case AuthAction.Auth.LoginCodePending:
    case SocialAuthAction.Auth.SocialAuthPending:
      return {
        ...state,
        error: undefined,
        status: LoginStatus.InProgress,
      };

    case AuthAction.Auth.LoginCompleted:
    case AuthAction.Auth.LoginCodeCompleted:
    case SocialAuthAction.Auth.SocialAuthDone:
      return {
        ...state,
        status: LoginStatus.LoggedIn,
        destination: response?.destination || body.destination,
        memberName: response?.memberName,
        accountId: response?.accountId,
      };

    case AuthAction.Auth.LoginFailed:
    case AuthAction.Auth.LoginCodeFailed:
    case SocialAuthAction.Auth.SocialAuthFailed:
      return {
        ...state,
        error: response?.message || body.message,
        status: LoginStatus.Failed,
      };

    default: {
      return state;
    }
  }
}
