import { Button, Form, Input, Text, LineThrough } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getQueryParams from '../../../lib/getQueryParams';
import useAnalyticsContext from '../../../lib/hooks/useAnalyticsContext';
import { testPassword } from '../../../lib/utils';
import { loginWithGBAccount } from '../../actions/login';
import { requestMagicLink } from '../../actions/magiclinks';
import ANALYTICS_EVENTS from '../../constants/analyticsEvents';
import { LoginStatus } from '../../reducers/login';
import FormSubHeader from '../FormSubHeader';
import MagicLinkButton from '../MagicLinkButton';
import styles from './LoginForm.module.css';

const cx = classnames.bind(styles);

/*
function isValidEmailOrUsername(str) {
  const ident = (str || '').trim();
  // https://hackernoon.com/the-100-correct-way-to-validate-email-addresses-7c4818f24643 - TL;DR: don't validate anything other than the "attempt"
  const at = ident.indexOf('@');
  if (at > 0 && ident.lastIndexOf('.') > at) {
    return true;
  }
  // This is validating what we KNOW to be true, NOT what the rules should be.
  if (ident.length < 15) {
    return true;
  }
  return false;
}
*/

export default function LoginForm({
  history,
  match,
}) {
  const useCode = useSelector(({ auth }) => !!auth.useCode);
  const loginError = useSelector(({ login }) => login.error);
  const loginStatus = useSelector(({ login }) => login.status);
  const isMagicLinkLoading = useSelector(({ magiclinks }) => magiclinks.isLoading);
  const magicLinkError = useSelector(({ magiclinks }) => magiclinks.magicLinkError);
  const magicLinkStatus = useSelector(({ magiclinks }) => magiclinks.modalVariant);
  const magicLinkDevice = useSelector(({ magiclinks }) => magiclinks.magicLinkDevice);
  const dispatch = useDispatch();

  const [identifier, setIdentifier] = useState('');
  const [identifierError, setIdentifierError] = useState(undefined);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(undefined);
  const [canSubmit, setCanSubmit] = useState(true);
  const [canRequestMagicLink, setCanRequestMagicLink] = useState(true);
  const analytics = useAnalyticsContext();

  const { partner } = match?.params || {};

  // see https://github.com/gas-buddy/identity-web/pull/192 for useCallback discussion
  const onIdentifierChange = useCallback(({ target }) => {
    setIdentifier(target.value);
    setIdentifierError(undefined);
  }, []);

  const onPasswordChange = useCallback(({ target }) => {
    setPassword(target.value);
    setPasswordError(undefined);
  }, []);

  const onSignInClick = useCallback((e) => {
    e.preventDefault();

    // Right now this is the only validation we can do until we fix the mess.
    const validIdentifier = identifier.trim() !== '';
    const validPassword = testPassword(password);

    if (!validIdentifier) {
      setIdentifierError('A valid email address or username is required.');
    }

    if (!validPassword) {
      setPasswordError('A valid password of at least 4 characters is required.');
    }

    if (!validIdentifier || !validPassword) {
      return;
    }

    analytics.tagEvent({ name: ANALYTICS_EVENTS.IAM_Login_Clicked });

    const returnUrl = getQueryParams(history.location.search).return_url;

    dispatch(
      loginWithGBAccount(partner, {
        identifier,
        password,
        return_url: returnUrl,
        query: history.location.search,
      }),
    );
  }, [analytics, dispatch, history.location.search, identifier, partner, password]);

  const onMagicLinkClick = useCallback((e) => {
    e.preventDefault();
    const validIdentifier = identifier.trim() !== '';

    if (!validIdentifier) {
      setIdentifierError('A valid email address or username is required.');
      return;
    }

    analytics.tagEvent({ name: ANALYTICS_EVENTS.IAM_Login_MagicLink_Requested });

    const { search } = history.location;

    const returnUrl = getQueryParams(search).return_url;

    dispatch(
      requestMagicLink({
        identifier,
        return_url: returnUrl,
        query: search,
        partner,
        useCode,
      }),
    );
  }, [analytics, dispatch, history.location, identifier, partner, useCode]);

  useEffect(() => {
    analytics.tagPageView({ name: ANALYTICS_EVENTS.IAM_Account_Login });
  }, [analytics]);

  useEffect(() => {
    setCanSubmit(identifier && password && !identifierError && !passwordError);
    setCanRequestMagicLink(!!identifier.length && !identifierError && !isMagicLinkLoading);
  }, [identifier, password, identifierError, passwordError, isMagicLinkLoading]);

  return (
    <React.Fragment>
      <FormSubHeader>
        Please enter your email and password. If you don&apos;t have a password, we&apos;ll email you a login
        {useCode ? ' code.' : ' link instead!'}
      </FormSubHeader>
      {!!loginError && (
        <Text as="p" color="orange">{loginError}</Text>
      )}
      <Form onSubmit={onSignInClick} method="POST">
        <div className={cx('formGroup')}>
          <Input
            id="email"
            name="identifier"
            data-testid="emailInput"
            label="Email or username"
            isValid={!identifierError && !magicLinkError}
            error={identifierError || (identifier && magicLinkError)}
            value={identifier}
            onChange={onIdentifierChange}
            autoComplete="email"
            className={cx('formEmailInput')}
          />
        </div>
        {(!!identifierError || (identifier && !!magicLinkError)) && <br />}
        <div className={cx('formGroup')}>
          <Input
            id="password"
            name="password"
            data-testid="passwordInput"
            type="password"
            label="Password"
            isValid={!passwordError}
            error={passwordError}
            value={password}
            onChange={onPasswordChange}
            autoComplete="current-password"
          />
        </div>
        {!!passwordError && <br />}
        <div className={cx('action')}>
          <Button
            data-test="loginButton"
            type="submit"
            className={cx('submit')}
            primary
            fluid
            loading={loginStatus === LoginStatus.InProgress}
            disabled={!canSubmit}
          >
            Log in
          </Button>
        </div>
      </Form>
      <LineThrough as="div" className={cx('divider')}>or</LineThrough>
      <div className={cx('action')}>
        <Form
          action="/login-link"
          className={cx('magicLinkForm')}
          onSubmit={onMagicLinkClick}
          method="POST"
        >
          <input type="hidden" value={identifier} />
          <MagicLinkButton
            disabled={!canRequestMagicLink}
            loading={isMagicLinkLoading}
            variant={magicLinkStatus}
            deviceLabel={magicLinkDevice}
            useCode={useCode}
          />
          <Text className={cx('disclaimer')}>
            We&apos;ll send you an email so you can log in with a
            {useCode ? ' short code' : ' simple click'}
            .
          </Text>
        </Form>
      </div>
    </React.Fragment>
  );
}

LoginForm.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      partner: PropTypes.string,
    }),
  }),
};

LoginForm.defaultProps = {
  match: undefined,
};
