import React from 'react';
import { Container } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';

export default function UnknownPage({ staticContext }) {
  staticContext.status = 404;
  return (
    <Container style={{ minHeight: 600, marginTop: 64 }}>
      <h1>Oops!</h1>
      <p>We couldn&apos;t find that page.</p>
    </Container>
  );
}

UnknownPage.propTypes = {
  staticContext: PropTypes.shape(),
};

UnknownPage.defaultProps = {
  staticContext: {},
};
