import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Input, CountryIcon } from '@gasbuddy/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { VALIDATION_TIMEOUT } from '../../constants';
import { detectCountryCode } from '../../../lib/utils';
import styles from './PostalCodeInput.module.css';

const cx = classnames.bind(styles);

const invalidIcon = <FontAwesomeIcon icon={faTimes} size="2x" className={cx('invalid')} />;

export default function PostalCodeInput({ onChange, onBlur, inline, ...rest }) {
  const { value, size, required } = rest;
  const [inputValue, setInputValue] = useState(value);
  const [countryCode, setCountryCode] = useState(() => detectCountryCode(value));
  const [isValid, setIsValid] = useState(() => (countryCode !== undefined ? true : undefined));

  const validateChange = useCallback((newValue) => {
    if (newValue === '') {
      setIsValid(!required);
      setCountryCode(undefined);
    } else {
      const newCountryCode = detectCountryCode(newValue);
      setCountryCode(newCountryCode);
      setIsValid(!!newCountryCode);
    }
  }, [required]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      validateChange(inputValue);
    }, VALIDATION_TIMEOUT);
    return () => clearTimeout(timerId);
  }, [inputValue, validateChange]);

  const handleChange = useCallback((e) => {
    e.preventDefault();
    setInputValue(e.target.value);
    onChange(e);
  }, [onChange]);

  const handleBlur = useCallback((e) => {
    e.preventDefault();
    validateChange(e.target.value);
    onBlur(e);
  }, [onBlur, validateChange]);

  const isInvalid = isValid === false; // it is considered valid if isValid is undefined
  const validityIcon = countryCode
    ? <CountryIcon countryCode={countryCode} size={size} className={cx('icon')} />
    : isInvalid && invalidIcon;

  return (
    <Input
      onChange={handleChange}
      onBlur={handleBlur}
      autoComplete="postal-code"
      innerComponent={validityIcon}
      {...rest}
    />
  );
}

PostalCodeInput.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  inline: PropTypes.bool,
};

PostalCodeInput.defaultProps = {
  onChange: () => {},
  onBlur: () => { },
  inline: false,
};
