import { combineReducers } from 'redux';
import config from './config';
import analytics from './analytics';
import auth from './auth';
import login from './login';
import password from './password';
import signup from './signup';
import social from './social';
import magiclinks from './magiclinks';

export { IdentifierStatus } from './auth';
export { SocialAccountStatus } from './social';
export { LoginStatus } from './login';
export { Action } from '../actions';

export default combineReducers({
  config,
  analytics,
  auth,
  login,
  password,
  signup,
  social,
  magiclinks,
});
