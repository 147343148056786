import React from 'react';
import PropTypes from 'prop-types';
import querystring from 'querystring';
import PasswordChangeForm from '../PasswordChangeForm';

export default function ChangePassword({ location }) {
  const queryParams = location.search ? querystring.parse(location.search.substring(1)) : {};
  const { token, source, return_url: destination } = queryParams;

  return (
    <PasswordChangeForm token={token} source={source} destination={destination} />
  );
}

ChangePassword.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

ChangePassword.defaultProps = {
  location: {},
};
