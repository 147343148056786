import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PasswordChangeForm from './PasswordChangeForm';
import { updatePassword, resetPasswordUpdated } from '../../actions/password';
import { requestMagicLink } from '../../actions/magiclinks';
import { hasLoggedInRecently } from '../../../lib/utils';

function mapStateToProps({ auth, password, magiclinks, config }) {
  return {
    identityHost: config.identityHost,
    isLoggedIn: !!auth.account_id,
    email: auth.email,
    token: auth.token,
    error: password.error,
    isUpdating: password.isUpdating,
    hasUpdated: password.hasUpdated,
    hasLoggedInRecently: hasLoggedInRecently(auth.lastLogin),
    isMagicLinkLoading: magiclinks.isLoading,
    modalVariant: magiclinks.modalVariant,
    magicLinkError: magiclinks.magicLinkError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSavePassword: body => dispatch(updatePassword(body)),
    onComplete: () => dispatch(resetPasswordUpdated()),
    requestMagicLink: body => dispatch(requestMagicLink(body)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordChangeForm));

export { default as PasswordResetSuccess } from './PasswordResetSuccess';
export { default as PasswordUpdateSuccess } from './PasswordUpdateSuccess';
export { default as PasswordUpdateRedirect } from './PasswordUpdateRedirect';
