import querystring from 'querystring';

export default function getReturnUrlForMagicLinkRequest(identityHost, returnUrl) {
  const isStagingOrProduction = identityHost.startsWith('iam.');
  const protocol = isStagingOrProduction ? 'https' : 'http';
  const endpoint = `${protocol}://${identityHost}/go`;

  // if a returnUrl param was provided, build the return url preserving it
  // This is needed for pay enrollment redirects to work
  if (returnUrl) {
    const query = querystring.stringify({ returnUrl });
    return `${endpoint}?${query}`;
  }
  return endpoint;
}
