import React from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Text } from '@gasbuddy/react-components';

export default function PasswordUpdateSuccess({ onDone }) {
  return (
    <React.Fragment>
      <Header as="h3">Password updated!</Header>
      <Text as="p">
        Your password was updated successfully.
        You can close this window or <a href="https://www.gasbuddy.com" title="GasBuddy Homepage">click here to return to GasBuddy.com</a>.
      </Text>
      <br />
      <Button
        as="a"
        href="/login"
        cta
        wide
        primary
        onClick={onDone}
      >
        Done
      </Button>
    </React.Fragment>
  );
}

PasswordUpdateSuccess.propTypes = {
  onDone: PropTypes.func.isRequired,
};
