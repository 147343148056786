import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { SocialAuthButtons as RawSocialAuthButtons } from '@gasbuddy/react-consumer-components';
import SocialStatus from '../../constants/socialStatus';

const AuthType = {
  SignIn: 'signup',
  LogIn: 'login',
};

function SocialAuthButtons({ type, ...rest }) {
  const { socialStatus } = rest;

  if (socialStatus === SocialStatus.AccountNotFound && type === AuthType.LogIn) {
    return (
      <Redirect to="/signup" />
    );
  }

  return (
    <RawSocialAuthButtons {...rest} />
  );
}

SocialAuthButtons.propTypes = {
  socialStatus: PropTypes.oneOf(Object.values(SocialStatus)),
  type: PropTypes.oneOf(Object.values(AuthType)),
};

SocialAuthButtons.defaultProps = {
  socialStatus: undefined,
  type: AuthType.LogIn,
};

export default SocialAuthButtons;
