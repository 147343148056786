import { Action } from '../actions/password';
import {
  ExpiredToken,
  IncorrectPassword,
  InvalidToken,
  UnauthenticatedUser,
} from '../../lib/error/UpdatePasswordError';

const defaultState = {
  isUpdating: undefined,
  hasUpdated: false,
  error: undefined,
};

const getErrorMessage = (code) => {
  switch (code) {
    case UnauthenticatedUser.code:
      return 'We are unable to authenticate you. Your password has not been changed. Please login again to update your password.';
    case IncorrectPassword.code:
      return 'Your current password is not correct. Please try again.';
    case ExpiredToken.code:
      return 'Your link is expired. Please request a new secure link.';
    case InvalidToken.code:
      return 'Your link is incorrect. Please request a new secure link.';
    default:
      return 'We were unable to change your password at this time. Your password has not been changed. Please try again later.';
  }
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case Action.Password.UpdatePending:
      return {
        ...state,
        isUpdating: true,
        error: undefined,
      };

    case Action.Password.UpdateCompleted:
      return {
        ...state,
        isUpdating: false,
        hasUpdated: true,
      };

    case Action.Password.UpdateFailed:
      return {
        ...state,
        isUpdating: false,
        hasUpdated: false,
        error: getErrorMessage(action?.payload?.response?.code),
      };

    case Action.Password.ResetUpdated:
      return {
        ...state,
        hasUpdated: false,
        error: undefined,
      };

    default:
      return state;
  }
}
