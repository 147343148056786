import { Action } from '../actions/social';
import SocialStatus from '../constants/socialStatus';

export const defaultState = {
  isChecking: false,
  isLinking: false,
  isLinked: false,
  status: SocialStatus.Unknown,
  email: undefined,
  memberId: undefined,
  network: undefined,
  error: undefined,
};

export const SocialAccountStatus = {
  Unknown: 0,
  InProgress: 1,
  HasSocial: 2,
  NeedsSocial: 3,
  NeedsGBAccount: 4,
};

export default function (state = defaultState, action) {
  const { payload, meta } = action;

  switch (action.type) {
    case Action.Auth.SocialStatusPending:
      return {
        ...state,
        isChecking: true,
        signedRequest: meta?.signedRequest,
      };

    case Action.Auth.SocialStatusDone:
      return {
        ...state,
        isChecking: false,
        status: payload.status,
        email: payload.socialEmail,
        memberId: payload.socialMemberId,
        network: payload.socialNetwork,
      };

    case Action.Auth.SocialAuthPending:
      return {
        ...state,
        signedRequest: meta?.signedRequest,
      };

    case Action.Auth.SocialAuthFailed:
      return {
        ...state,
        status: payload.response?.code,
        email: payload.response?.socialEmail,
        memberId: payload.response?.socialMemberId,
        network: payload.response?.socialNetwork,
      };

    case Action.Auth.SocialStatusReset:
      return {
        isChecking: false,
        status: SocialStatus.Unknown,
        email: undefined,
        memberId: undefined,
        network: undefined,
      };

    case Action.Auth.SocialLinkPending:
      return {
        ...state,
        isLinking: true,
      };

    case Action.Auth.SocialLinkDone:
      // TODO handle error here
      return {
        ...state,
        isLinking: false,
        isLinked: true,
      };

    case Action.Auth.SocialLinkFailed:
      return {
        ...state,
        isLinking: false,
        isLinked: false,
        error: payload.response?.message,
      };

    default:
      return { ...state };
  }
}
