import {
  generateRequest,
  Action,
} from '.';

export const loginWithGBAccount = (partner, body) => generateRequest({
  method: 'POST',
  endpoint: partner ? `/login/${partner}` : '/login',
  /* https://www.npmjs.com/package/redux-api-middleware#rsaacredentials
   * in some versions of Safari, without below 'credentials' being set,
   * the cookies will not be sent to server which leads to a new session
   * creation with each POST request
   */
  credentials: 'same-origin',
  body,
  types: [
    Action.Auth.LoginPending,
    Action.Auth.LoginCompleted,
    Action.Auth.LoginFailed,
  ],
});

export const redeemLoginCode = body => generateRequest({
  method: 'POST',
  endpoint: '/login-code',
  credentials: 'include',
  body,
  types: [
    Action.Auth.LoginCodePending,
    Action.Auth.LoginCodeCompleted,
    Action.Auth.LoginCodeFailed,
  ],
});

export const loginWithGBMagicLink = body => generateRequest({
  method: 'GET',
  endpoint: `/login-link/${body.code}`,
  credentials: 'same-origin',
  types: [
    Action.Auth.LoginPending,
    Action.Auth.LoginCompleted,
    Action.Auth.LoginFailed,
  ],
});

export const grantConsent = body => generateRequest({
  method: 'POST',
  endpoint: '/oauth/consent',
  credentials: 'include',
  body,
  types: [
    Action.Auth.ConsentPending,
    Action.Auth.ConsentCompleted,
    Action.Auth.ConsentFailed,
  ],
});

export const denyConsent = body => generateRequest({
  method: 'POST',
  endpoint: '/oauth/deny-consent',
  credentials: 'include',
  body,
  types: [
    Action.Auth.ConsentPending,
    Action.Auth.ConsentCompleted,
    Action.Auth.ConsentFailed,
  ],
});
