import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Application from './Application';

const mapStateToProps = ({ auth }) => ({
  accountId: auth.accountId,
  memberName: auth.membername,
  destination: auth.destination,
  loginStatus: auth.status,
});

export default withRouter(connect(mapStateToProps)(Application));
