import React from 'react';
import PropTypes from 'prop-types';

export default function DeferredRedirect({ to, delay }) {
  setTimeout(() => {
    window.location.assign(to);
  }, delay);

  return (
    <React.Fragment />
  );
}

DeferredRedirect.propTypes = {
  to: PropTypes.string.isRequired,
  delay: PropTypes.number,
};

DeferredRedirect.defaultProps = {
  delay: 5000, // 5 seconds
};
