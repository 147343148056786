export const VALIDATION_TIMEOUT = 750; // in ms

export const SOCIAL_NETWORKS = {
  FACEBOOK: 1,
  GOOGLE: 2,
};

export const LOCALYTICS_CUSTOM_DIMENSIONS = {
  USER_ID: 14,
  USER_NAME: 15,
};
