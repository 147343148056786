import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useQueryParam(key) {
  const location = useLocation();

  return useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(key);
  }, [key, location]);
}
