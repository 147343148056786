import {
  FlexGrid,
  Loader,
  LoadingMask,
  Visibility,
  LineThrough,
} from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { goTo } from '../../../lib/goTo';
import ReactGTM from '../../../lib/gtm';
import useAnalyticsContext from '../../../lib/hooks/useAnalyticsContext';
import useReturnUrl from '../../../lib/hooks/useReturnUrl';
import ANALYTICS_EVENTS from '../../constants/analyticsEvents';
import { LoginStatus } from '../../reducers/login';
import FormHeader from '../FormHeader';
import LoginForm from '../LoginForm';
import SocialAuthButtons from '../SocialAuthButtons';
import styles from './Login.module.css';

const cx = classnames.bind(styles);

const LOADER = <Loader size="sm" />;
const REDIRECT_LOADER = <Loader size="sm"><span>Success! Redirecting...</span></Loader>;

export default function Login({
  loginStatus,
  accountId,
  memberName,
  destination,
}) {
  const analytics = useAnalyticsContext();
  const isLoading = loginStatus === LoginStatus.InProgress;
  const isRedirecting = loginStatus === LoginStatus.LoggedIn;
  const returnUrl = useReturnUrl();

  useEffect(() => {
    if (loginStatus === LoginStatus.LoggedIn && typeof window !== 'undefined') {
      analytics.identifyUser({
        id: memberName,
      });
      analytics.tagEvent({ name: ANALYTICS_EVENTS.IAM_User_Successfully_Logged_In });

      // TODO: This might be unnecessary. In which case, we wouldn't need accountId as a dependency
      ReactGTM.event({
        event: 'LoginComplete',
        userID: accountId,
      });

      goTo(destination);
    }
  }, [accountId, analytics, destination, loginStatus, memberName]);

  return (
    <LoadingMask
      className={cx('loadingMask')}
      isLoading={isLoading || isRedirecting}
      loader={isRedirecting ? REDIRECT_LOADER : LOADER}
    >
      <FormHeader>Welcome back</FormHeader>
      <FlexGrid container>
        <FlexGrid.Column mobile={12} desktop={7} className={cx('column')}>
          <LoginForm />
        </FlexGrid.Column>
        <Visibility desktop={false} className={cx('fullWidth')}>
          <FlexGrid.Column mobile={12}>
            <LineThrough as="div" className={cx('divider')}>or</LineThrough>
          </FlexGrid.Column>
        </Visibility>
        <FlexGrid.Column mobile={12} desktop={5} className={cx('column', 'social')}>
          <SocialAuthButtons
            magicLinkReturnUrl={returnUrl}
            returnUrl={returnUrl}
          />
        </FlexGrid.Column>
      </FlexGrid>
    </LoadingMask>
  );
}

Login.propTypes = {
  accountId: PropTypes.number,
  memberName: PropTypes.string,
  destination: PropTypes.string,
  loginStatus: PropTypes.number,
};

Login.defaultProps = {
  accountId: undefined,
  memberName: undefined,
  destination: undefined,
  loginStatus: undefined,
};
