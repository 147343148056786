import { generateRequest } from '.';

export const Action = {
  Password: {
    UpdatePending: 'UpdatePassword_PENDING',
    UpdateCompleted: 'UpdatePassword_FULFILLED',
    UpdateFailed: 'UpdatePassword_FAILED',
    ResetUpdated: 'ResetUpdated',
  },
};

export const updatePassword = body => generateRequest({
  method: 'POST',
  endpoint: '/password',
  body,
  types: [
    Action.Password.UpdatePending,
    Action.Password.UpdateCompleted,
    Action.Password.UpdateFailed,
  ],
});

export const resetPasswordUpdated = () => ({
  type: Action.Password.ResetUpdated,
});
