import { connect } from 'react-redux';
import Router from './Router';

const mapStateToProps = ({ analytics, auth }) => {
  const { localytics: localyticsKey } = analytics;
  const { account_id: accountId, membername } = auth;

  return {
    accountId,
    analyticsConfig: typeof window !== 'undefined' ? {
      localytics: {
        domain: 'gasbuddy.com',
        key: localyticsKey,
      },
    } : undefined,
    memberName: membername,
  };
};

export default connect(mapStateToProps)(Router);
