import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Input, Message, Modal, Text, LineThrough } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import getQueryParams from '../../../lib/getQueryParams';
import getReturnUrlForMagicLinkRequest from '../../../lib/getReturnUrlForMagicLinkRequest';
import useAnalyticsContext from '../../../lib/hooks/useAnalyticsContext';
import { testNewPassword, testPassword } from '../../../lib/utils';
import ANALYTICS_EVENTS from '../../constants/analyticsEvents';
import { MagicLinkStatus } from '../../reducers/magiclinks';
import FormHeader from '../FormHeader';
import FormSubHeader from '../FormSubHeader';
import MagicLinkSent from '../MagicLinkSent';
import PasswordUpdateRedirect from './PasswordUpdateRedirect';
import PasswordUpdateSuccess from './PasswordUpdateSuccess';

// TODO: Put this custom hook in @gasbuddy/react-hooks and use imported hook from there instead later
const usePasswordValidation = (passwordValidator, validationMessage) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(undefined);

  const validatePassword = (value) => {
    setPassword(value);

    if (!value) {
      setError('Please enter a value');
    } else if (!passwordValidator(value)) {
      setError(validationMessage);
    } else {
      setError(undefined);
    }
  };

  return [password, validatePassword, error];
};

export default function PasswordChangeForm({
  identityHost,
  email: defaultEmail,
  token,
  onSavePassword,
  requestMagicLink,
  onComplete,
  isUpdating,
  isLoggedIn,
  hasUpdated,
  hasLoggedInRecently,
  error,
  source,
  destination,
  history,
  isMagicLinkLoading,
  modalVariant,
  magicLinkError,
}) {
  // this could use a useEffect hook later to integrate server-side validation for matching current password
  const [oldPassword, setCurrentPassword] = useState('');
  const [oldPasswordError, setCurrentPasswordError] = useState(undefined);
  const analytics = useAnalyticsContext();

  const handleCurrentPasswordChange = ({ target }) => {
    const val = target?.value;

    if (!val) {
      setCurrentPasswordError('Please enter a value');
    } else if (!testPassword(val)) {
      setCurrentPasswordError('Password must be at least 4 characters');
    } else {
      setCurrentPassword(target.value);
      setCurrentPasswordError(undefined);
    }
  };

  const [newPassword, validateNewPassword, newPasswordError] = usePasswordValidation(
    testNewPassword,
    'Password must be 5 - 128 characters long.',
  );

  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [confirmedPasswordError, setConfirmedPasswordError] = useState(undefined);

  const checkConfirmedPassword = (confirmedPasswordValue, newPasswordValue) => {
    setConfirmedPassword(confirmedPasswordValue);

    if (newPasswordValue.length && !confirmedPasswordValue.length) {
      return;
    }

    const arePasswordsEqual = confirmedPasswordValue === newPasswordValue;
    const errorMessage = !arePasswordsEqual
      ? 'Passwords don\'t match. Please try again.'
      : undefined;
    setConfirmedPasswordError(errorMessage);
  };

  const handleFormSubmission = useCallback((e) => {
    e.preventDefault();

    // if user is requesting magic link, they won't enter new password
    // however, since the form has a nested form, handleFormSubmission will fire as well unintentionally
    // If newPassword is not set, request actually is not to update password hence return
    if (!newPassword.length) {
      return;
    }

    onSavePassword({
      token,
      defaultEmail,
      oldPassword,
      newPassword,
    });
  }, [token, oldPassword, newPassword, defaultEmail, onSavePassword]);

  // if we have a token, we do not require an old password.
  // if we don't have a token, we only require an old password if the user has an older session
  const requireOldPassword = !token && !hasLoggedInRecently;
  const offerMagicLink = requireOldPassword;

  const [canSubmit, setCanSubmit] = useState(true);
  useEffect(() => {
    let hasError = !!newPasswordError || !!confirmedPasswordError;
    let ableToSubmit = !!newPassword && !!confirmedPassword && !hasError;
    if (requireOldPassword) {
      hasError = hasError || !!oldPasswordError;
      ableToSubmit = ableToSubmit && !!oldPassword && !hasError;
    }

    setCanSubmit(ableToSubmit);
  }, [newPassword, confirmedPassword, newPasswordError, confirmedPasswordError, requireOldPassword, oldPassword, oldPasswordError]);

  const onRequestMagicLink = async (e) => {
    e.preventDefault();

    analytics.tagEvent({ name: ANALYTICS_EVENTS.IAM_PasswordReset_MagicLink_Requested });

    const { search } = history.location;
    const returnUrl = getQueryParams(search).return_url;
    await requestMagicLink({
      identifier: defaultEmail,
      return_url: returnUrl,
      query: search,
    });
  };

  const subtext = offerMagicLink
    ? 'Can\'t remember, or don\'t have a password? You can email yourself a secure link below.'
    : 'Your account has been previously authorized. Enter your new password below.';

  // Links built by Core Services would look like:
  // https://iam.gasbuddy.com/password?token={token}&source=login-link&return_url={web link or app deep link}
  // A redirect should only be performed if the url was requested from a source other than web with token and source present in url
  const shouldRedirect = hasUpdated && !isLoggedIn
    && !!token && source === 'login-link'
    && destination.length && !destination.startsWith(getReturnUrlForMagicLinkRequest(identityHost));

  const magicLinkErrorMessage = magicLinkError;

  return (
    <React.Fragment>
      <Form loading={isUpdating} method="POST" onSubmit={handleFormSubmission}>
        <Modal
          content={() => (
            <MagicLinkSent linkType="secure" variant={modalVariant} />
          )}
          forceIsShowing={!!modalVariant}
          size="sm"
          closeIcon={false}
        />
        <FormHeader>Update password</FormHeader>
        <br />
        {!!error && (
          <Message
            color="red"
            icon={<FontAwesomeIcon icon={faExclamation} size="xs" />}
          >
            {error}
          </Message>
        )}
        <FormSubHeader>{subtext}</FormSubHeader>
        <Modal
          size="sm"
          content={() => (
            shouldRedirect
              ? <PasswordUpdateRedirect destination={destination} autoRedirect />
              : <PasswordUpdateSuccess onDone={onComplete} />
          )}
          forceIsShowing={hasUpdated}
          onClose={onComplete}
          closeIcon={false}
        />
        {!!token && (
          <Input
            type="hidden"
            name="token"
            value={token}
          />
        )}
        {requireOldPassword && (
          <Input
            id="oldPassword"
            name="oldPassword"
            type="password"
            bordered
            isValid={oldPassword !== undefined && !oldPasswordError}
            onChange={handleCurrentPasswordChange}
            label="Current Password"
            error={oldPasswordError}
            value={oldPassword}
            required
            autoComplete="current-password"
          />
        )}
        <Input
          id="newPassword"
          name="newPassword"
          type="password"
          bordered
          isValid={newPassword !== undefined && !newPasswordError}
          onChange={(e) => { validateNewPassword(e.target.value); }}
          onBlur={(e) => { checkConfirmedPassword(confirmedPassword, e.target.value); }}
          error={newPasswordError}
          label="New Password"
          value={newPassword}
          required
          autoComplete="new-password"
          maxLength={128}
        />
        <Input
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          bordered
          isValid={confirmedPassword !== undefined && !confirmedPasswordError}
          onChange={(e) => { checkConfirmedPassword(e.target.value, newPassword); }}
          onBlur={(e) => { checkConfirmedPassword(newPassword, e.target.value); }}
          error={confirmedPasswordError}
          label="Confirm Password"
          value={confirmedPassword}
          required
          autoComplete="new-password"
          maxLength={128}
        />
        <br />
        <Button
          type="submit"
          cta
          primary
          fluid
          disabled={!canSubmit}
        >
          Save Password
        </Button>
      </Form>
      {!!offerMagicLink && (
        <React.Fragment>
          <LineThrough as="div">OR</LineThrough>
          <br />
          <Form onSubmit={onRequestMagicLink} data-test="magicLinkForm">
            <Button
              secondary
              fluid
              loading={isMagicLinkLoading}
              type="submit"
              data-test="requestMagicLink"
            >
              Email me a secure link
            </Button>
          </Form>
          <Text as="p" centered color={magicLinkErrorMessage ? 'orange' : 'midnight'}>
            <small>
              {magicLinkErrorMessage || 'A secure link acts as your authentication to change your password.'}
            </small>
          </Text>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

PasswordChangeForm.propTypes = {
  identityHost: PropTypes.string,
  email: PropTypes.string,
  token: PropTypes.string,
  onSavePassword: PropTypes.func,
  requestMagicLink: PropTypes.func,
  onComplete: PropTypes.func,
  isUpdating: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  hasUpdated: PropTypes.bool,
  hasLoggedInRecently: PropTypes.bool,
  error: PropTypes.string,
  source: PropTypes.string,
  destination: PropTypes.string,
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isMagicLinkLoading: PropTypes.bool,
  modalVariant: PropTypes.oneOf(Object.values(MagicLinkStatus)),
  magicLinkError: PropTypes.string,
};

PasswordChangeForm.defaultProps = {
  identityHost: 'iam.gasbuddy.com',
  email: undefined,
  token: undefined,
  isUpdating: false,
  hasUpdated: false,
  isLoggedIn: false,
  source: undefined,
  destination: undefined,
  hasLoggedInRecently: false,
  error: undefined,
  isMagicLinkLoading: false,
  modalVariant: undefined,
  magicLinkError: undefined,
  onSavePassword: () => {},
  onComplete: () => {},
  requestMagicLink: () => {},
};
