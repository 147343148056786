import { generateRequest } from '.';
import { SOCIAL_NETWORKS } from '../constants';

export const Action = {
  Auth: {
    SocialAuthPending: 'SocialAuthPending',
    SocialAuthDone: 'SocialAuthDone',
    SocialAuthFailed: 'SocialAuthFailed',
    SocialStatusPending: 'SocialStatusPending',
    SocialStatusDone: 'SocialStatusDone',
    SocialStatusFailed: 'SocialStatusFailed',
    SocialStatusReset: 'SocialStatusReset',
    SocialLinkPending: 'SocialLinkPending',
    SocialLinkDone: 'SocialLinkDone',
    SocialLinkFailed: 'SocialLinkFailed',
  },
};

export const loginWithFacebook = body => generateRequest({
  method: 'POST',
  endpoint: '/social/login/facebook',
  body,
  types: [
    {
      type: Action.Auth.SocialAuthPending,
      meta: { signedRequest: body.signedRequest },
    },
    Action.Auth.SocialAuthDone,
    Action.Auth.SocialAuthFailed,
  ],
});

export const loginWithGoogle = body => generateRequest({
  method: 'POST',
  endpoint: '/social/login/google',
  body,
  types: [
    {
      type: Action.Auth.SocialAuthPending,
      meta: { signedRequest: body.signedRequest },
    },
    Action.Auth.SocialAuthDone,
    Action.Auth.SocialAuthFailed,
  ],
});

export const checkFacebookAccount = body => generateRequest({
  method: 'POST',
  endpoint: '/social/status/facebook',
  body,
  types: [
    {
      type: Action.Auth.SocialStatusPending,
      meta: { signedRequest: body.signedRequest },
    },
    Action.Auth.SocialStatusDone,
    Action.Auth.SocialStatusFailed,
  ],
});

export const checkGoogleAccount = body => generateRequest({
  method: 'POST',
  endpoint: '/social/status/google',
  body,
  types: [
    {
      type: Action.Auth.SocialStatusPending,
      meta: { signedRequest: body.signedRequest },
    },
    Action.Auth.SocialStatusDone,
    Action.Auth.SocialStatusFailed,
  ],
});

export const linkSocialAccount = ({ socialNetwork, ...body }) => generateRequest({
  method: 'POST',
  endpoint: `/social/link/${socialNetwork === SOCIAL_NETWORKS.FACEBOOK ? 'facebook' : 'google'}`,
  body,
  types: [
    Action.Auth.SocialLinkPending,
    Action.Auth.SocialLinkDone,
    Action.Auth.SocialLinkFailed,
  ],
});

export const resetSocialStatus = () => ({
  type: Action.Auth.SocialStatusReset,
});
