import BusinessError from './BusinessError';

export class UnauthenticatedUser extends BusinessError {
  constructor(message = 'Failed to get member information from session', status = 403) {
    super('UnauthenticatedUser', status, message);
    Error.captureStackTrace(this, UnauthenticatedUser);
  }
}

export class IncorrectPassword extends BusinessError {
  constructor(message = 'Current password is incorrect', status = 401) {
    super('IncorrectPassword', status, message);
    Error.captureStackTrace(this, IncorrectPassword);
  }
}

export class ExpiredToken extends BusinessError {
  constructor(message = 'Token is expired', status = 401) {
    super('ExpiredToken', status, message);
    Error.captureStackTrace(this, ExpiredToken);
  }
}

export class InvalidToken extends BusinessError {
  constructor(message = 'Token is invalid', status = 401) {
    super('InvalidToken', status, message);
    Error.captureStackTrace(this, InvalidToken);
  }
}
