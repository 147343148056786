import { connect } from 'react-redux';
import Signup from './Signup';

function mapStateToProps({ signup }) {
  const {
    status: loginStatus,
    accountId,
    memberName,
    destination,
    network: socialNetwork,
  } = signup;

  return {
    loginStatus,
    accountId,
    memberName,
    destination,
    socialNetwork,
  };
}

export default connect(mapStateToProps)(Signup);
