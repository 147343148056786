import isUuid from 'is-uuid';
import { addMinutes, isBefore } from 'date-fns';
import { SOCIAL_NETWORKS } from '../common/constants';


const REGEX = {
  // eslint-disable-next-line no-useless-escape, max-len
  EMAIL: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  MEMBERNAME: /^[a-zA-Z0-9]{5,15}$/,
  MEMBERNAME_SANTIZATION: /[^a-zA-Z0-9]/g,
  PASSWORD: /^.{4,}$/,
  NEW_PASSWORD: /^.{5,128}$/,
  ZIP: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  POSTAL: /(^[ABCEGHJKLMNPRSTVXY][\d][A-Z]\s?[\d][A-Z][\d]$)/i, // https://en.wikipedia.org/wiki/Postal_codes_in_Canada#Table_of_all_postal_codes
  POSTCODE: /^\d{4}$/, // only four digits
};

export const Countries = {
  US: {
    code: 'USA',
    label: 'United States',
  },
  CA: {
    code: 'CAN',
    label: 'Canada',
  },
  AU: {
    code: 'AUS',
    label: 'Australia',
  },
};

export const getSocialNetworkString = (socialNetwork) => {
  switch (socialNetwork) {
    case SOCIAL_NETWORKS.FACEBOOK:
      return 'Facebook';
    case SOCIAL_NETWORKS.GOOGLE:
      return 'Google';
    default: return null;
  }
};

/**
 * Determine the result of an object's keys' truthiness
 *
 * @param {any} obj Object containing any number of keys which have a true or false value
 * @returns {boolean} Result of all key's truthiness evaluated
 */
export const allKeysTruthiness = obj => (
  Object.entries(obj).length > 0
    ? Object.keys(obj)
      .map(key => obj[key])
      .reduce((a, b) => (a && b), true)
    : false
);

/**
 * Tests that an email address is valid
 *
 * @param {string} email - an email address to test
 * @returns {boolean} - Whether the email appears to be valid
 */
export const testEmail = email => REGEX.EMAIL.test(email);

/**
 * Tests that a GasBuddy name meets complexity requirements
 *
 * @param {string} password - an membername to test
 * @returns {boolean} - Whether the membername appears to meet complexity requirements
 */
export const testMembername = membername => REGEX.MEMBERNAME.test(membername);

/**
 * Tests that a password meets complexity requirements
 *
 * @param {string} password - an password string to test
 * @returns {boolean} - Whether the password appears to meet complexity requirements
 */
export const testPassword = password => REGEX.PASSWORD.test(password);

/**
 * Tests that a password meets new complexity requirements
 *
 * @param {string} password - an password string to test
 * @returns {boolean} - Whether the new password appears to meet complexity requirements
 */
export const testNewPassword = password => REGEX.NEW_PASSWORD.test(password);

/**
 * Tests that a US ZipCode validates
 *
 * @param {string} zipCode - a zip code to test (short or full)
 * @returns {boolean} - Whether the zip code validates
 */
export const testZipcode = zipCode => REGEX.ZIP.test(zipCode);

/**
 * Tests that a Canadian Postal Code validates
 *
 * @param {string} postalCode - a canadian postal code to test (optional space)
 * @returns {boolean} - Whether the postal code validates
 */
export const testPostalCode = postalCode => REGEX.POSTAL.test(postalCode);

/**
 * Tests that an Australian Post Code validates
 *
 * @param {string} postCode - an Australian post code to test
 * @returns {boolean} - Whether the post code validates
 */
export const testPostCode = postCode => REGEX.POSTCODE.test(postCode);

/**
 * Tests that given string is a valid v1 - v5 uuid or not
 *
 * @param {string} guid - a uuid / guid string
 * @returns {boolean} - Whether the guid is valid
 */
export const testGuid = guid => isUuid.anyNonNil(guid);

/**
 * Detect country code with the provided postal code.
 * Only postal codes of US, Canada, and Australia are supported, otherwise return undefined.
 *
 * @param {*} postalCode postal code for detecting country code
 */
export const detectCountryCode = (postalCode) => {
  if (testZipcode(postalCode)) {
    return Countries.US.code;
  }

  if (testPostalCode(postalCode)) {
    return Countries.CA.code;
  }

  if (testPostCode(postalCode)) {
    return Countries.AU.code;
  }

  return undefined;
};

/**
 * Suggests a validly-formed membername based on a suggestion, such as an email address.
 * @param {string} base - A string representing a base suggestion
 * @returns {string} - a validly formed membername
 */
export const membernameSuggestion = base => (
  `${
    base.replace(REGEX.MEMBERNAME_SANTIZATION, '').substring(0, 10)
  }${
    Math.floor(Math.random() * 90000) + 10000
  }`
);

/**
 * Truncates a username based on length.
 * @param {string} string - username to potentially truncate
 * @param {number} maxSize - how long a string can be, before truncated
 * @param {number} bufferChars - when truncating, how many chars at the start and end to preserve,
 * truncate the rest
 * @param {string} truncateWithChar - Optional. When truncating, what string to truncate with
 * @returns {string} truncated username if over max size, or the username as-is
 */
export const truncateForDisplay = (string, maxSize = 15, bufferChars = 6, truncateWithChar = '…') => {
  if (bufferChars * 2 >= string.length) {
    return string;
  }

  return string.length > maxSize
    ? `${string.slice(0, bufferChars)}${truncateWithChar}${string.slice(-bufferChars)}`
    : string;
};

/**
 * Check if the given str is null or empty
 * @param {string} str - The string to be checked
 */
export const isNullOrEmpty = str => (str === null || str === '');

/**
 * Capitalize the first character of the given string
 * @param {string} str - The string to be capitalized its first character
 */
export const upperCaseFirst = (str) => {
  if (isNullOrEmpty(str)) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatEvidence = (identifier, credentials) => ({
  try_legacy: true,
  identifier: {
    identifier,
    namespace: identifier.includes('@') ? 'email' : 'username',
    credentials,
  },
});

export const formatCredentials = (password, otp) => {
  const credentials = [];

  if (password && password.length) {
    credentials.push({ type: 'password', value: password });
  }

  if (otp && otp.length) {
    credentials.push({ type: 'otp', value: otp });
  }

  return credentials;
};

export const isJsRequest = req => req.xhr || req.headers['content-type'].includes('application/json');

export const hasLoggedInRecently = (lastLogin, offset = 0) => !!lastLogin
  && isBefore(Date.now(), addMinutes(lastLogin, 15 + offset));

export function noop() {
  // No operation performed.
}
