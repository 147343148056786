import { createAction } from 'redux-api-middleware';

export function generateRequest({ body, ...options }) {
  return createAction({
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      gbcsrf: window.gbcsrf,
    },
    body: body && JSON.stringify(body),
    ...options,
  });
}

export const Action = {
  Auth: {
    LoginFailed: 'Login_FAILED',
    LoginCompleted: 'Login_FULFILLED',
    LoginPending: 'Login_PENDING',
    LogoutRequested: 'Logout',
    LoggedOut: 'Logout_FULFILLED',
    AuthReset: 'AuthReset',
    LoginCodePending: 'LoginCode_PENDING',
    LoginCodeCompleted: 'LoginCode_FULFILLED',
    LoginCodeFailed: 'LoginCode_FAILED',
    ConsentPending: 'Consent_PENDING',
    ConsentCompleted: 'Consent_FULFILLED',
    ConsentFailed: 'Consent_FAILED',
  },
};

export const resetAuth = () => ({
  type: Action.Auth.AuthReset,
  meta: { apiAction: false },
  body: {},
});
