import React from 'react';
import { Helmet } from 'react-helmet-async';

const DESCRIPTION = 'GasBuddy lets you search for Gas Prices by city, state, zip code, with listings for all cities in the USA and Canada. Updated in real-time, with national average price for gasoline, current trends, and mapping tools.';
const THUMBNAIL = 'https://images.gasbuddy.com/images/websites/gasbuddy/apps/cellphone-app.png';
const TITLE = 'Find The Nearest Gas Stations & Cheapest Prices | Save On Gas';

export default function DefaultHelmet() {
  return (
    <Helmet>
      <title>{TITLE}</title>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta content="IE=edge,chrome=1" httpEquiv="X-UA-Compatible" />
      <meta name="description" content={DESCRIPTION} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={TITLE} />
      <meta property="og:image" content={THUMBNAIL} />
      <meta property="og:description" content={DESCRIPTION} />
      <meta name="twitter:title" content={TITLE} />
      <meta name="twitter:image" content={THUMBNAIL} />
      <meta name="twitter:description" content={DESCRIPTION} />
      <link rel="shortcut icon" id="favicon" type="image/x-icon" href="https://static.gasbuddy.com/web/favicon.ico" />
      <link rel="apple-touch-icon" sizes="152x152" href="https://images.gasbuddy.com/images/websites/gasbuddy/apple-touch-icon.png" />
      <link rel="alternate" href="android-app://gbis.gbandroid/gasbuddy/" />
    </Helmet>
  );
}
