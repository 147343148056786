import { createMiddleware } from 'redux-api-middleware';
import Router from '../common/components/Router';
import entryPoint from './renderEntryPoint';
import hot from './hot-loader';

const render = () => entryPoint({
  router: hot(Router),
  initialState: window.PreloadedState,
  apiMiddleware: createMiddleware(),
});

render();
