import { Header, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React from 'react';
import useAnalyticsContext from '../../../lib/hooks/useAnalyticsContext';
import ANALYTICS_EVENTS from '../../constants/analyticsEvents';
import { MagicLinkStatus } from '../../reducers/magiclinks';
import SupportLink from '../SupportLink';

const isLoginLink = linkType => linkType === 'login';

const logAnalyticsEvent = (analyticsContext, linkType, eventName) => {
  if (isLoginLink(linkType)) {
    analyticsContext.tagEvent({ name: eventName });
  }
};

export default function MagicLinkSent({ variant, identifierIsUsername, deviceLabel, linkType }) {
  const analytics = useAnalyticsContext();
  const emailSentString = identifierIsUsername
    ? 'the email address registered to your username'
    : 'that email address';

  const actionText = isLoginLink(linkType) ? 'log you in' : 'authorize you';

  switch (variant) {
    case MagicLinkStatus.Sent: {
      return (
        <React.Fragment>
          <Header as="h3">Email Sent!</Header>
          <Text as="p">
            We&apos;ve sent a {linkType} link to {emailSentString}. <br />
            Your {linkType} link will expire within the next <Text bold>30 minutes</Text>.
          </Text>
          <Text as="p" bold>
            <Text>If you don&apos;t receive your {linkType} link, </Text>
            <SupportLink>contact support</SupportLink>.
          </Text>
        </React.Fragment>
      );
    }

    // TODO: There is nothing that causes this status to be set
    case MagicLinkStatus.Throttled: {
      return (
        <React.Fragment>
          <Header as="h3">Email already sent.</Header>
          <Text as="p">
            We&apos;ve recently sent a {linkType} link to {emailSentString}.
            You can try again in <strong>5 minutes.</strong>
          </Text>
          <Text as="p" bold>
            <Text>If you don&apos;t receive your {linkType} link, </Text>
            <SupportLink>contact support</SupportLink>.
          </Text>
        </React.Fragment>
      );
    }

    case MagicLinkStatus.InvalidDevice: {
      logAnalyticsEvent(analytics, linkType, ANALYTICS_EVENTS.IAM_Login_MagicLink_WrongDevice);
      return (
        <React.Fragment>
          <Header as="h3">We couldn&apos;t {actionText}.</Header>
          <Text as="p">
            Please open your {linkType} link on the device you requested it on: <br />
          </Text>
          <Text as="p" bold>{deviceLabel}</Text>
          <Text as="p">
            Or you may request another {linkType} link to {actionText} on this device.
          </Text>
        </React.Fragment>
      );
    }

    case MagicLinkStatus.InvalidCode: {
      logAnalyticsEvent(analytics, linkType, ANALYTICS_EVENTS.IAM_Login_MagicLink_InvalidOrExpired);
      return (
        <React.Fragment>
          <Header as="h3">We couldn&apos;t {actionText}.</Header>
          <Text as="p">
            The emailed link you clicked is either invalid, expired, or previously used.
          </Text>
          <Text as="p">
            To log in, request another link and be sure to use it within <Text bold>30 minutes</Text> of receiving the email.
          </Text>
        </React.Fragment>
      );
    }

    default: {
      return false;
    }
  }
}

MagicLinkSent.propTypes = {
  variant: PropTypes.number,
  identifierIsUsername: PropTypes.bool,
  deviceLabel: PropTypes.string,
  linkType: PropTypes.oneOf(['login', 'secure']),
};

MagicLinkSent.defaultProps = {
  variant: undefined,
  identifierIsUsername: false,
  deviceLabel: '',
  linkType: 'login',
};
